<template>
	<page-container class="index" :page="page">
		<heading-image>
			<hero-image v-if="page.headingImage" type="fullscreen">
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p>
						<span>{{ defaults[locale].homepage.headingImageTitle }}</span>
					</p>
					<div v-parse-links v-html="defaults[locale].homepage.headingImageContent ?? ''" />
				</div>
				<div class="slider-overlay" />
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img class="object-fit" :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>

			<booking-form />
		</heading-image>

		<main-content>
			<div class="columns column4 intro-image">
				<picture>
					<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
					<source :srcset="defaults[locale].homepage.introImage" />
					<img
						:src="defaults[locale].homepage.introImage"
						:alt="defaults[locale].homepage.introImageAlt"
						loading="lazy"
					/>
				</picture>
			</div>
			<div class="columns column8">
				<h1>{{ page.title }}</h1>
				<div v-parse-links v-html="page.content" />
			</div>
		</main-content>

		<mosaic :items="mosaicData" />

		<contentblocks :items="page.contentblocks">
			<template #blocks-header>
				<div class="row blocks-header white align-center">
					<div class="columns column12">
						<h2>
							{{ defaults[locale].homepage.sectionContentblocksTitle }}
						</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionContentblocksContent ?? ''" />
					</div>
				</div>
			</template>
		</contentblocks>

		<promoblocks :items="promoblocksData">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>
							{{ defaults[locale].homepage.sectionPromotionTitle }}
						</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent ?? ''" />
					</div>
				</div>
			</template>
		</promoblocks>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const { data: mosaicData } = await useWebsiteFetch('mosaic', {
	query: { language: locale.value },
	key: `${locale.value}/mosaic`,
});

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 2;
	background: rgb(0 0 0 / 25%);
}

.hero-content {
	position: absolute;
	max-width: 1400px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 50%;
	margin: 0 auto -70px;
	text-align: center;
	color: #fff;
	letter-spacing: 2px;

	:deep(span) {
		color: #fff;
		font-size: 4.4rem;
		font-family: var(--heading-font-family);
		font-weight: 700;
		margin: 0 0 10px;
		width: 100%;
		display: inline-block;
		line-height: 1;
	}

	:deep(p) {
		font-size: 1.8em;
	}
}

.hero-image {
	img {
		animation: zoom-in 11000ms ease-in-out forwards;
	}
}

@media (max-width: 1080px) {
	.intro-image {
		display: none;
	}
}

@media (max-width: 880px) {
	.hero-content {
		:deep(span) {
			font-size: 3.4rem;
		}

		:deep(p) {
			font-size: 1.6em;
			padding: 0 10px;
		}
	}
}
</style>
